<template>
  <el-dialog :visible.sync="dialogVisible" top="2%" width="90%" title="选择题库" :center="true" :before-close="doClose">
    <section class="CourseTables">
      <div class="operationControl" style="border:0">
        <div class="searchbox">
          <div title="题库名称" class="searchboxItem">
            <span class="itemLabel">题库名称:</span>
            <el-input clearable v-model="questionBankName" type="text" size="small" placeholder="请输入题库名称" />
          </div>
          <span title="题库类别" class="searchboxItem ci-full flexcc">
            <span class="itemLabel">题库类别:</span>
            <el-cascader v-model="categoryCode" :options="TreeList" :props="propsTree" clearable filterable
              size="small"></el-cascader>
          </span>
          <div>
            <el-button class="bgc-bv" @click="getData()">查询</el-button>
          </div>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
            row-key="questionBankId" style="width: 100%" @selection-change="change" stripe border>
            <el-table-column type="selection" width="50px" align="center" :reserve-selection="true">
            </el-table-column>
            <el-table-column label="题库名称" align="center" show-overflow-tooltip prop="questionBankName" />
            <el-table-column label="题库类别" align="center" show-overflow-tooltip prop="questionCategory" />
            <!-- <el-table-column
              label="培训类型"
              align="center"
              show-overflow-tooltip
              prop="trainTypeName"
            />
            <el-table-column
              label="行业类型"
              align="center"
              show-overflow-tooltip
              prop="industryName"
            />
            <el-table-column
              label="岗位类型"
              align="center"
              show-overflow-tooltip
              prop="postName"
            />
            <el-table-column
              label="职业/工种"
              align="center"
              show-overflow-tooltip
              prop="occupationName"
            />
            <el-table-column
              label="培训等级"
              align="center"
              show-overflow-tooltip
              prop="trainLevelName"
            /> -->
            <el-table-column label="题目数量" align="center" prop="questionBankNum">
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <div class="flexcb">
        <div class="flexca">
          <div style="width: 140px;">题库失效时间:</div>
          <el-date-picker v-model="questionBankEndDate" size="small" value-format="yyyy-MM-dd" :clearable="false"
            type="date" :picker-options="pickerOptions" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="flexca">
          <div style="width: 70px;">赠送规则:</div>
          <el-select v-model="questionBankMode" placeholder="请选择" size="small">
            <el-option v-for="item in questionBankModeList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
      <div class="Batch">
        <div class="BatchBox">
          <div class="BatchBoxFirst">
            <p>选择题库</p>
            <p>（{{ backArr.length }}）</p>
          </div>
          <div class="BatchBoxSecond ovy-a">
            <div class="BatchforBox">
              <div v-for="(item, index) in backArr" :key="index" class="BatchforFlex">
                <div class="BatchImgBox" @click="deleteImg(item)">
                  <img :src="require('@/assets/close_.png')" alt="" @mouseover="transToWhite(index, $event)"
                    @mouseleave="transToBlack(index, $event)" />
                </div>
                <span style="padding-left:5px">{{
                  item.questionBankName
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="dialog-footer" style="margin-top:10px;display:flex;justify-content: center;">
        <div>
          <el-button class="bgc-bv" @click="dialogClose">取消</el-button>
          <el-button class="bgc-bv" @click="doOk">确定</el-button>
        </div>
      </span>
    </section>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
// import treePopupQuestion from "@/components/treePopupQuestion";
import { mapGetters } from "vuex";
export default {
  name: "courseMoreTable",
  props: ['date'],
  components: {
    Empty,
    PageNum,
    // treePopupQuestion,
  },
  mixins: [List],
  data() {
    return {
      questionBankName: "",
      traintype: "",
      multipleTable: [],
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      dialogVisible: false,
      params: {}, // tree 参数
      ruleForm: {
        Trainingtype: "",
      },
      start: false,
      backArr: [],
      backArrall: [],
      categoryCode: "",
      TreeList: [],
      propsTree: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      questionBankEndDate: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      questionBankMode: "10", // 赠送规则
      questionBankModeList: [], // 赠送规则 - 字典数据
    };
  },
  computed: {
    ...mapGetters({
      traintypeList: "getTraintype",
    }),
  },
  created() {
    this.getDictionary();
    this.getTree();
  },
  updated() {
    setTimeout(() => {
      this.tableData.forEach((item, index) => {
        this.$refs.multipleTable.toggleRowSelection(
          item,
          this.backArr.some((one) => item.questionBankId == one.questionBankId)
        );
      });
    }, 200);
  },
  methods: {
    // 获取字典
    getDictionary() {
      // 审核状态
      let arr1 = this.$setDictionary("PROJECT_QUESTION_BANK_MODE", "list");
      for (const key in arr1) {
        this.questionBankModeList.push({
          value: key,
          label: arr1[key],
        });
      }
    },
    /* 鼠标移入移出 */
    transToWhite(index, e) {
      e.target.src = require("@/assets/error.png");
    },
    transToBlack(index, e) {
      e.target.src = require("@/assets/close_.png");
    },
    tinit() {
      this.init();
    },
    change(rowArr) {
      console.log(rowArr);
      this.backArr = rowArr;
      this.multipleTable = rowArr;
    },
    showPopUp(projectId, date) {
      this.questionBankEndDate = date
      this.dialogVisible = true;
      this.projectId = projectId;
      this.start = true;
      if (this.start) {
        this.getData();
        this.getconfigquestionbank();
      }
    },
    /* 题库类别 */
    getTree() {
      this.$post(
        "/miniapp/category/enable/tree",
        { disabledLevels: "1,2,3" },
        3000,
        true,
        2
      ).then((ret) => {
        this.TreeList = ret.data;
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId || "",
      };
      if (this.questionBankName) {
        params.questionBankName = this.questionBankName;
      }
      if (this.categoryCode) {
        params.categoryCode = this.categoryCode;
      }
      if (this.start) {
        this.doFetch({
          url: "/biz/project/queryQuestionBank",
          params,
          pageNum,
        });
      }
      setTimeout(() => {
        this.tableData.forEach((item, index) => {
          this.backArr.forEach((items, index) => {
            this.tableData.forEach((item, index) => {
              if (items.questionBankId == item.questionBankId) {
                this.$refs.multipleTable.toggleRowSelection(item, true);
              }
            });
          });
        });
      }, 200);
    },
    getconfigquestionbank() {
      this.$post("/biz/project/queryConfigQuestionBank", {
        projectId: this.projectId,
      })
        .then((ret) => {
          console.log(ret);
          this.backArr = ret.data;
        })
        .catch((err) => {
          return;
        });
    },
    deleteImg(itemobj) {
      var newArr = this.backArr.filter(
        (item) => item.questionBankId != itemobj.questionBankId
      );
      this.backArr = newArr;
      this.multipleTable.find((el) => {
        if (el.questionBankId == itemobj.questionBankId) {
          this.$refs.multipleTable.toggleRowSelection(el, false);
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 760 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      const { backArr } = this;
      if (backArr.length) {
        this.$emit("eventBus", backArr, this.projectId, this.questionBankEndDate,this.questionBankMode);
        this.dialogVisible = false;
        this.backArr = [];
        this.$refs.multipleTable.clearSelection();
      } else {
        this.$message({
          message: "请选择要配置的题库",
          type: "warning",
        });
      }
    },
    dialogClose() {
      this.dialogVisible = false;
      this.backArr = [];
      this.questionBankName = ''
      this.categoryCode = ''
      this.$refs.multipleTable.clearSelection();
      //   this.$emit("eventBus");
      // this.params = {};
    },
    doClose() {
      this.dialogVisible = false;
      this.backArr = [];
      this.$refs.multipleTable.clearSelection();
      this.questionBankName = ''
      this.categoryCode = ''
      //   this.$emit("eventBus");
      // this.params = {};
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
        (m != "00" ? m + "分" : "") +
        (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
};
</script>
<style lang="less">
.CourseTables {
  .el-radio {
    .el-radio__label {
      display: none;
    }

    .el-radio__inner {
      width: 18px;
      height: 18px;
      border-color: #777;
    }

    .el-radio__inner:hover {
      border-color: #5c6be8;
    }

    .el-radio__input.is-checked .el-radio__inner {
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }

  .Batch {
    padding: 1rem 1rem;

    .BatchBox {
      display: flex;
      height: 120px;
      border: 1px solid #ebeef5;
      border-radius: 5px;

      .BatchBoxFirst {
        width: 260px;
        background: #f2f7fd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .BatchBoxSecond {
        width: 100%;

        .BatchforBox {
          display: flex;
          flex-wrap: wrap;

          .BatchforFlex {
            display: flex;
            align-items: center;
            padding: 5px 20px;
            cursor: pointer;

            .BatchImgBox {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
